<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    scrollable
    hide-footer
    title="Product Gallery"
  >
    <div class="tw-flex tw-flex-col tw-p-2 tw-h-full product-gallery-container tw-justify-center">
      <div
        v-if="!loading"
        class="tw-flex tw-flex-row tw-mb-2 tw-items-end tw-gap-4"
      >
        <div class="tw-flex tw-flex-col tw-relative tw-items-center">
          <div>
            <b-avatar
              rounded
              :size="60"
              :src="mainProductImage"
              :alt="productTitle"
              :title="productTitle"
            >
              <feather-icon
                v-if="isEmpty(mainProductImage)"
                :size="32"
                icon="ImageIcon"
              />
            </b-avatar>
          </div>
          <CountryFlag
            v-if="marketplaceFlag"
            class="tw-absolute"
            style="right: -10px; bottom: -2px"
            :country="marketplaceFlag.toLowerCase()"
          />
        </div>
        <div class="tw-flex tw-flex-col">
          <div>
            <span class="tw-font-bold">ASIN</span>:
            <a
              v-if="productUrl"
              :href="productUrl"
              target="_blank"
            >
              {{ asin }}
            </a>
            <span v-else>{{ asin }}</span>
          </div>
          <div>{{ productTitle }}</div>
        </div>
      </div>
      <SpinnerLoader v-if="loading" />
      <DxGallery
        v-else
        id="gallery"
        :data-source="dataSource"
        :loop="false"
        :show-nav-buttons="false"
        :show-indicator="true"
        :on-selection-changed="onSelectionChanged"
        height="80%"
      />
      <div
        class="tw-flex tw-flex-row tw-w-full tw-gap-2 tw-justify-center tw-pt-5"
        style="height: 52px"
      >
        <DxButton
          v-if="!loading && selectedItem !== mainImage"
          :key="saving ? `saving` : `default`"
          :width="140"
          text="Make Default"
          type="success"
          styling-mode="contained"
          @click="onMakeDefault"
        >
          <template v-if="!saving">
            Make Default
          </template>
          <SpinnerLoader
            v-else
            class="text-white"
          />
        </DxButton>
      </div>
    </div>
  </b-modal>
</template>
<script>

import DxGallery from 'devextreme-vue/gallery'
import DxButton from 'devextreme-vue/button'
import { BAvatar } from 'bootstrap-vue'
import CountryFlag from 'vue-country-flag'
import SpinnerLoader from '@/components/SpinnerLoader.vue'

export default {
  name: 'ProductImageGallery',
  components: {
    BAvatar,
    SpinnerLoader,
    DxGallery,
    DxButton,
    CountryFlag,
  },
  props: {
    visible: {
      type: Boolean,
    },
    asin: {
      type: String,
      required: true,
    },
    marketplace: {
      type: String,
      required: true,
    },
    marketplaceFlag: {
      type: String,
      default: null,
    },
    mainProductImage: {
      type: String,
      default: null,
    },
    productTitle: {
      type: String,
      default: null,
    },
    productUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataSource: [],
      loading: true,
      mainImage: null,
      selectedItem: undefined,
      saving: false,
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onSelectionChanged(e) {
      const [image] = e.addedItems
      if (image) {
        this.selectedItem = image
      } else {
        this.selectedItem = null
      }
    },
    onMakeDefault() {
      this.saving = true
      this.$store.dispatch('product/setDefaultImage', {
        asin: this.asin,
        marketplace: this.marketplace,
        image: this.selectedItem,
      })
        .then(() => {
          this.$bvToast.toast('Main image updated', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.mainImage = this.selectedItem
        })
        .catch(() => {
          this.$bvToast.toast('Error updating main image', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    loadData() {
      this.loading = true
      this.dataSource = []
      this.$store.dispatch('product/fetchImages', { asin: this.asin, marketplace: this.marketplace })
        .then(response => {
          this.dataSource = response.items || []
          this.mainImage = response.main
          this.selectedItem = response.main
        })
        .catch(() => {
          this.$bvToast.toast('Error loading product images', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
  },
}
</script>

<style scoped lang="scss">
  .product-gallery-container {
    min-height: 500px;
  }
</style>
